import axios from "axios";

const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;
// console.log(TOKEN);

/* LOCAL URL */
// const BASE_URL = "http://localhost:5050/api/";

/* LIVE URL */
// const BASE_URL = "http://64.20.34.166:5050/api/";

/* DOMAIN URL */
const BASE_URL = "https://api.fabricdesigneroutlet.com/api/";

/* BUILD URL */
// const BASE_URL = "/api/";

// const TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTRkNmFhZWNhMTZkNGRkNTVjMWRhMSIsImVtYWlsIjoiaGFpZGVya2hhbGlsMDAwMEBnbWFpbC5jb20iLCJpYXQiOjE2NzA4MzkxODgsImV4cCI6MTY3MTA5ODM4OH0.Bb1rjhhV9gSRNFknh8SDGlr5ha8OFQH_3z8VB5yPDug";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});
/* LOCAL URL */
// export const IMG_URL = "http://localhost:5050/";

/* LIVE URL */
// export const IMG_URL = "http://64.20.34.166:5050/";

/* DOMAIN URL */
export const IMG_URL = "https://api.fabricdesigneroutlet.com/";

/* BUILD URL */
// export const IMG_URL = "/";
